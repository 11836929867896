export default function parsePath(path) {
  var pathname = path || `/`
  var search = ``
  var hash = ``

  var hashIndex = pathname.indexOf(`#`)
  if (hashIndex !== -1) {
    hash = pathname.substr(hashIndex)
    pathname = pathname.substr(0, hashIndex)
  }

  var searchIndex = pathname.indexOf(`?`)
  if (searchIndex !== -1) {
    search = pathname.substr(searchIndex)
    pathname = pathname.substr(0, searchIndex)
  }

  return {
    pathname: pathname,
    search: search === `?` ? `` : search,
    hash: hash === `#` ? `` : hash,
  }
}
